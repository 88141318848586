/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-05-30",
    versionChannel: "nightly",
    buildDate: "2023-05-30T00:21:44.167Z",
    commitHash: "8d6c08df4d10fb2f77511af3d605c61a5b94b7bc",
};
